.question-no-box-listening {
  background-color: #156cf2;
  color: #fff;
  border: 2px solid #000 ;
  font-weight: bold;
  cursor: pointer;
}

.question-no-box-reading {
  background-color: "#fff";
  color: #000;
  border: 2px solid #156cf2 ;
  font-weight: bold;
  cursor: pointer;
}